<template>
    <div class="main-container" dir="ltr">
        <div class="row mb-4" style="margin-bottom: 1.5rem !important;margin-top: 1rem;">
            <div class="col-md-12 my-auto">
                <h5 style="text-align: right">محاضرات قد تعجبك</h5>
            </div>
        </div>   
        <div class="row justify-content-center video-items slick-initialized slick-slider" style="font-size: 14px;"> 
            <div class="slick-list draggable">
                <div class="slick-track">
                    <vueper-slides 
                        class="no-shadow"
                        :visible-slides="3"
                        slide-multiple
                        :gap="3"
                        :slide-ratio="1 / 4"
                        :dragging-distance="200"
                        :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: 1 } }">
                        <vueper-slide v-for="publish in TopContent.slice(0,6)" :key="publish.id" :image="publish.img" :title="publish.title_ar" @click="GoToContent(publish)" />
                    </vueper-slides>
                </div>
            </div>
        </div>
        <!-- <div class="row justify-content-center video-items slick-initialized slick-slider" style="font-size: 14px;" data-slick='{"slidesToShow": 3, "slidesToScroll": 1}'> -->
            <!-- <i class="fas fa-chevron-left slick-arrow" style="display: inline;"></i>
                <div class="slick-list draggable">
                    <div class="slick-track">
                        <div id="" class="col-xl-12 slick-slide slick-cloned" aria-hidden="true" tabindex="-1" style="width: 347px;" :class="{ active: index === 0 }" v-for="(publish, index) in TopContent.slice(0,5)" :key="index" >
                            <div class="video-item-card">
                        <img :src="publish.carsoul_img" class="custom-logo" alt="Sayara">
                        <ul class="video-meta" dir="ltr">
                            <li>
                                <i class="fas fa-eye"></i>{{publish.views}} مشاهدة 
                            </li>
                            <li>
                                <i class="fas fa-calendar-alt"></i>
                                <a href="#" class="float-right">{{publish.title_ar}}</a>
                            </li>
                        </ul>
                            </div>
                    </div>
                </div>
            </div>
            <i class="fas fa-chevron-right slick-arrow" style="display: inline;"></i> -->
        <!-- </div> -->
        <div class="row mb-4">
            <div class="col-md-12 my-auto">
                <h5 style="text-align: right">كل الكورسات</h5>
            </div>
        </div>
        <div class="row justify-content-center " dir="rtl">
            <div class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="publish in All" :key="publish.id">
                <div class="card">
                    <div class="video-item-card">
                        <a @click="GoToSeriesContent(publish)">
                            <div class="card__cover">
                                <img :src="publish.img" class="custom-logo" alt="Sayara">
                                <SeriesSVG/>
                            </div>
                        </a>
                        <div class="video-content">
                            <div class="d-flex">
                                <div class="my-auto ">
                                    <a @click="GoToSeriesContent(publish)">
                                        <h5>{{publish.name_ar}}</h5>
                                    </a>
                                    <ul class="list-inline">
                                        <li class="list-inline-item">
                                            <a class="author " @click.prevent="GoToSeriesContent(publish)">{{publish.cp_name}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { HTTPSPA } from '@/Api/http-Spay';
import { ref } from '@vue/reactivity';
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import SeriesSVG from '../SVG/SeriesSVG.vue';
import { watchEffect } from '@vue/runtime-core';
import { useToast } from "vue-toastification";
export default {
    name: 'AppHome',
    components: { VueperSlides, VueperSlide, SeriesSVG },
    async setup() {
    const router = useRouter();
    const cookie = useCookie();
    const toast = useToast();
    const All = ref([]);
    const TopContent = ref([]);

    try {
      await HTTP.get(`GetAllCourses.php`).then((res) => {
        All.value = res.data.Courses; 
      });
    } catch (err) {
      console.log(err);
    }
    try {
      await HTTP.get(`GetTopContent.php`).then((res) => {
        TopContent.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }

    watchEffect(() => {
            if (!cookie.isCookieAvailable("msisdn") && !cookie.isCookieAvailable("status")) {
            let urlParams = new URLSearchParams(window.location.search);
            // console.log(urlParams.get('msisdn'))
            if(urlParams.get('phoneNumber') != null) {
                 if(urlParams.get('phoneNumber').length === 12) {
              // console.log(urlParams.get('phoneNumber'))
              if(urlParams.has('phoneNumber')) {
                HTTPSPA.post(`CheckLogin.php?username=`+urlParams.get(`phoneNumber`) +`&serviceId=2222&operatorID=2`).then((res) => {
                    if (res.data.status == 1 ) {
                        cookie.setCookie('msisdn', urlParams.get('phoneNumber'), { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('status', res.data.status, { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('MINTUES', res.data.MINTUES, { expire: 60 * res.data.MINTUES, })
                        // toast.success("مرحبا بك معنا  ", { timeout: 1000 });
                    } else if (res.data.status == 0 && res.data.responseCode == 118) {
                        cookie.setCookie("msisdn", `2491`+urlParams.get('phoneNumber'), { expire: 60 * 120, });
                        toast.warning("عفوا انت مشترك مسبقا ولم يتم تجديد اشتراكك", { timeout: 2500, });
                    } else {
                        toast.info("عفوا انت لست مشترك في كورساتي" , { timeout: 2500 });
                    }
                    if(cookie.getCookie("Content_id")!=null){
                        toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
                        setTimeout(() =>  router.push({name: "Content", params: { id: cookie.getCookie("Content_id") } }), 2000);
                    } else {
                        setTimeout(() => router.push({ path: "/" }), 2500);
                    }
                })
            }
            } else {
              if(urlParams.has('phoneNumber')) {
                HTTPSPA.post(`CheckLogin.php?username=2491`+urlParams.get(`phoneNumber`) +`&serviceId=2222&operatorID=2`).then((res) => {
                    if (res.data.status == 1 ) {
                        cookie.setCookie('msisdn', `2491`+urlParams.get('phoneNumber'), { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('status', res.data.status, { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('MINTUES', res.data.MINTUES, { expire: 60 * res.data.MINTUES, })
                        // toast.success("مرحبا بك معنا  ", { timeout: 1000 });
                    } else if (res.data.status == 0 && res.data.responseCode == 118) {
                        cookie.setCookie("msisdn", `2491`+urlParams.get('phoneNumber'), { expire: 60 * 120, });
                        toast.warning("عفوا انت مشترك مسبقا ولم يتم تجديد اشتراكك", { timeout: 2500, });
                    } else {
                        toast.info("عفوا انت لست مشترك في كورساتي" , { timeout: 2500 });
                    }
                    if(cookie.getCookie("Content_id")!=null){
                        toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
                        setTimeout(() =>  router.push({name: "Content", params: { id: cookie.getCookie("Content_id") } }), 2000);
                    } else {
                        setTimeout(() => router.push({ path: "/" }), 2500);
                    }
                })
            }
            }
            }
            
          } else {
              HTTPSPA.post(`CheckLogin.php?username=`+cookie.getCookie("msisdn")+`&serviceId=2222&operatorID=2`).then((res) => {
                if (res.data.status === 0 ) {
                    this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
                }
              })
          }
        });
    const GoToContent = (publish) => {
        if(cookie.isCookieAvailable("phone")) {
          router.push({ name: "Contents", params: { content_id: publish.id } });
        } else {
            window.open("http://courseatk.net/MTN_LandingPage_APIs/subscribe.php?portalId=1");
        //   window.open("http://sudanipay.com/subscription/nashoof-sd/nashoof-sd", "_self");
                //   router.push({ name: "Contents", params: { content_id: publish.id } });

        }
    };
    const GoToSeriesContent = (publish) => {
        if(cookie.isCookieAvailable("phone")) {
          router.push({ name: "SeriesContent", params: { series_id: publish.id } });
        } else {
            window.open("http://courseatk.net/MTN_LandingPage_APIs/subscribe.php?portalId=1");
        }
    };
    return { All, TopContent ,GoToSeriesContent, GoToContent};
    
  },
    methods: {
      logEvents (eventName, params) {
         this.events += `<strong>${eventName}</strong>, ${JSON.stringify(params)}<br>`
      }
    },
}
</script>

<style >

.vueperslide {
 white-space: normal;
background-size: cover;
flex-shrink: 0;
display: block;
width: 100%;
position: relative;
border-radius: 20px;
}
.vueperslide--clone-1 {
 position:absolute;
 top:0;
 bottom:0;
 right:100%
}
.vueperslides--rtl .vueperslide--clone-1 {
 right:auto;
 left:100%
}
.vueperslide[href] {
 -webkit-user-drag:none
}
.vueperslide__image {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 background-size:cover
}
.vueperslide__loader {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 align-items:center;
 justify-content:center
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 height:100%;
 margin:auto
}
.vueperslides--fade .vueperslide {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 opacity:0;
 transition:ease-in-out opacity;
 transition-duration:inherit
}
.vueperslides--fade .vueperslide--active,
.vueperslides--fade .vueperslide--visible {
 z-index:1;
 opacity:1
}
.vueperslides--slide-image-inside .vueperslide {
 overflow:hidden
}
.vueperslides--3d .vueperslide {
 position:absolute;
 z-index:-1;
 height:100%
}
.vueperslides--3d .vueperslide--previous-slide,
.vueperslides--3d .vueperslide--active,
.vueperslides--3d .vueperslide--next-slide {
 z-index:0
}
.vueperslides--3d .vueperslide--active {
 z-index:1
}
.vueperslides--3d .vueperslide[face=front] {
 transform:rotateY(90deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=right] {
 transform:rotateY(90deg) translate(50%);
 transform-origin:100% 0
}
.vueperslides--3d .vueperslide[face=back] {
 transform:rotateY(270deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=left] {
 transform:rotateY(270deg) translate(-50%);
 transform-origin:0 0
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before,
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 content:"";
 position:absolute;
 bottom:100%;
 left:-1em;
 right:-1em;
 height:2em;
 box-shadow:0 0 20px #00000040;
 z-index:2
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 top:100%;
 bottom:auto
}
.vueperslides__arrows {
 color:#fff
}
.vueperslides__arrows--outside {
 color:currentColor
}
.vueperslides__arrow {
 top:50%;
 background-color:transparent;
 border:none;
 opacity:.7
}
.vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrow--next {
 right:auto;
 left:.5em
}
.vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrow--prev {
 left:auto;
 right:.5em
}
.vueperslides__arrow:hover {
 opacity:1
}
.vueperslides__arrows--outside .vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--next {
 right:auto;
 left:-3.5em
}
.vueperslides__arrows--outside .vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--prev {
 left:auto;
 right:-3.5em
}
.vueperslides__paused {
 top:.7em;
 right:.7em;
 opacity:0;
 text-shadow:0 0 3px rgba(0,0,0,.4);
 z-index:1
}
.vueperslides:hover .vueperslides__paused {
 opacity:1
}
.vueperslides__bullets:not(.vueperslides__bullets--outside) {
 color:#fff
}
.vueperslides__bullet {
 margin:1.5em .6em;
 padding:0;
 border:none;
 background:none
}
.vueperslides__bullet .default {
 width:12px;
 height:12px;
 border-radius:12px;
 border:1px solid currentColor;
 background-color:transparent;
 box-shadow:0 0 1px #00000080,0 0 3px #0000004d;
 transition:.4s ease-in-out;
 box-sizing:border-box
}
.vueperslides__bullet .default span {
 display:none
}
.vueperslides__bullet--active .default {
 border-width:6px
}
.vueperslide,
.vueperslide__image {
 background-position:center
}
.vueperslide__video {
 outline:none
}
.vueperslide--no-pointer-events:before {
 content:"";
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 display: flex;
flex: 1 1 auto;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
background: #FC0;
height: 55px;
bottom: 0px;
position: absolute;
width: 100%;
border: 2px solid #e1c967;
font-size: 16px;
color: #4f4c4c;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
}
.vueperslide__content-wrapper.parallax-fixed-content,
.vueperslide--has-image-inside .vueperslide__content-wrapper,
.vueperslide--has-video .vueperslide__content-wrapper {
 position:absolute;
 z-index:2;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
 pointer-events:none
}
.vueperslides {
 position:relative;
 min-height: 25vh;
}
.vueperslides--fixed-height .vueperslides__inner,
.vueperslides--fixed-height .vueperslides__parallax-wrapper,
.vueperslides--fixed-height .vueperslide {
 height:inherit
}
.vueperslides--fixed-height .vueperslides__parallax-wrapper {
 padding-bottom:0!important
}
.vueperslides--fixed-height.vueperslides--bullets-outside {
 margin-bottom:4em
}
.vueperslides__inner {
 position:relative;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 min-height: 25vh;
}
.vueperslides__parallax-wrapper {
 position:relative;
 overflow:hidden;
 min-height: 25vh;
}
.vueperslides--3d .vueperslides__parallax-wrapper {
 overflow:visible
}
.vueperslides__track {
 position:absolute;
 top:0;
 height:100%;
 left:0;
 right:0;
 overflow:hidden;
 z-index:1
}
.vueperslides--parallax .vueperslides__track {
 height:200%;
 transform:translateY(0)
}
.vueperslides--touchable .vueperslides__track {
 cursor:ew-resize;
 cursor:-webkit-grab;
 cursor:grab
}
.vueperslides--touchable .vueperslides__track--mousedown,
.vueperslides--touchable .vueperslides__track--dragging {
 cursor:-webkit-grabbing;
 cursor:grabbing
}
.vueperslides--3d .vueperslides__track {
 overflow:visible;
 perspective:100em
}
.vueperslides__track-inner {
 white-space:nowrap;
 transition:.5s ease-in-out transform;
 height:100%;
 display:flex
}
.vueperslides--no-animation .vueperslides__track-inner {
 transition-duration:0s!important
}
.vueperslides--fade .vueperslides__track-inner {
 white-space:normal;
 transition:none
}
.vueperslides--3d .vueperslides__track-inner {
 transform-style:preserve-3d
}
.vueperslides__track--mousedown .vueperslides__track-inner {
 transition:.25s ease-in-out transform!important
}
.vueperslides__track--dragging .vueperslides__track-inner {
 transition:none
}
.vueperslides__arrow {
 position:absolute;
 font-size:inherit;
 color:inherit;
 text-align:center;
 transform:translateY(-50%);
 transition:.3s ease-in-out;
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 line-height:1
}
.vueperslides__arrow svg {
 vertical-align:middle;
 stroke:currentColor;
 fill:none;
 width:3.5em;
 padding:1em;
 stroke-width:1;
 transition:.3s ease-in-out;
 box-sizing:border-box;
  display: none
}
.vueperslides__arrow svg:hover {
 stroke-width:1.3
}
.vueperslides__paused {
 position:absolute;
 transition:.3s ease-in-out
}
.vueperslides__bullets {
 display:flex;
 justify-content:center;
 position:absolute;
 bottom:0;
 left:0;
 right:0
}
.vueperslides__bullets--outside {
 position:relative
}
.vueperslides__bullets button,
.vueperslides__bullet {
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 display:flex;
 justify-content:center;
 align-items:center;
 color:inherit
}
.vueperslides__bullets button::-moz-focus-inner,
.vueperslides__bullet::-moz-focus-inner {
 border:0
}
.vueperslides__fractions {
 position:absolute;
 top:.8em;
 left:.5em;
 z-index:2;
 padding:.2em 1em;
 border:1px solid rgba(255,255,255,.5);
 border-radius:2em;
 background:rgba(255,255,255,.2);
 color:#fff
}
.vueperslides__progress {
 position:absolute;
 top:0;
 left:0;
 right:0;
 z-index:2;
 height:6px;
 color:#000000b3
}
.vueperslides__progress>* {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 background:currentColor;
 transition:.3s ease-in-out
}
@media(max-width:768px){
.vueperslides__bullets {
        top:-10px;
}
}








</style>